import { useWizardFormContext } from '@react-admin/ra-form-layout'
import { Toolbar, Button, Grid } from '@mui/material'
import { SaveButton, useTranslate } from 'react-admin'

/** A WizardToolbar which skips the validation on the Save button, having it always enabled. */
export const PermissiveWizardToolbar: React.FC = () => {
  const { hasNextStep, hasPreviousStep, goToNextStep, goToPreviousStep } =
    useWizardFormContext()
  const translate = useTranslate()

  return (
    <Toolbar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {hasPreviousStep && (
            <Button variant="text" onClick={() => goToPreviousStep()}>
              {translate('ra-form-layout.action.previous')}
            </Button>
          )}
        </Grid>
        <Grid item>
          {hasNextStep ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => goToNextStep()}
            >
              {translate('ra-form-layout.action.next')}
            </Button>
          ) : (
            <SaveButton alwaysEnable />
          )}
        </Grid>
      </Grid>
    </Toolbar>
  )
}
