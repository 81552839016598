import { useState, useCallback, useMemo, useEffect } from 'react'
import { Button, useRecordContext } from 'react-admin'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material'
import { CompareArrows, Close as CloseIcon } from '@mui/icons-material'
import { useTransferService } from './TransferServiceModal.hooks'
import {
  TransferServiceForm,
  FormRecord,
} from '../../atoms/TransferServiceForm'

export const TransferServiceModal = () => {
  const [open, setOpen] = useState(false)
  const record = useRecordContext()

  const {
    selectedClient,
    setSelectedClient,
    selectedProject,
    setSelectedProject,
    selectedEndCustomer,
    setSelectedEndCustomer,
    handleTransfer,
    resetSelections,
    fetchPreview,
    previewServices,
    includeRelatedServices,
    setIncludeRelatedServices,
  } = useTransferService(record?.id)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(
    (_event?: unknown, reason?: string) => {
      if (reason === 'backdropClick') return false
      setOpen(false)
      resetSelections()
    },
    [resetSelections]
  )

  const formRecord: FormRecord = useMemo(
    () => ({
      clientId: selectedClient?.id ?? null,
      projectId: selectedProject?.id ?? null,
      endCustomerId: selectedEndCustomer?.id ?? null,
      includeRelatedServices,
    }),
    [
      selectedClient,
      selectedProject,
      selectedEndCustomer,
      includeRelatedServices,
    ]
  )

  const handleClientChange = useCallback(
    (value: string) => {
      const client = value ? { id: value, name: '' } : null
      setSelectedClient(client)
      setSelectedProject(null)
      setSelectedEndCustomer(null)
    },
    [setSelectedClient, setSelectedProject, setSelectedEndCustomer]
  )

  useEffect(() => {
    if (selectedClient?.id) {
      fetchPreview()
    }
  }, [
    fetchPreview,
    selectedClient?.id,
    selectedProject?.id,
    selectedEndCustomer?.id,
    includeRelatedServices,
  ])

  const handleProjectChange = useCallback(
    (value: string) => {
      const project = value ? { id: value, name: '' } : null
      setSelectedProject(project)
    },
    [setSelectedProject]
  )

  const handleEndCustomerChange = useCallback(
    (value: string) => {
      const endCustomer = value ? { id: value, name: '' } : null
      setSelectedEndCustomer(endCustomer)
    },
    [setSelectedEndCustomer]
  )

  const handleSubmit = useCallback(async () => {
    const success = await handleTransfer()

    if (success) handleClose()
  }, [handleClose, handleTransfer])

  return (
    <>
      <Button
        label="Transfer"
        onClick={handleOpen}
        startIcon={<CompareArrows />}
      />
      {open && (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography variant="h6" component="div">
              Transfer service
            </Typography>
            <Tooltip title="Cancel transfer">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogTitle>
          <DialogContent>
            <TransferServiceForm
              formRecord={formRecord}
              onSubmit={handleSubmit}
              onClientChange={handleClientChange}
              onProjectChange={handleProjectChange}
              onEndCustomerChange={handleEndCustomerChange}
              onIncludeRelatedServicesChange={setIncludeRelatedServices}
              selectedClientId={selectedClient?.id}
              selectedProjectId={selectedProject?.id}
              selectedEndCustomerId={selectedEndCustomer?.id}
              previewServices={previewServices}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
