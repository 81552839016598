import React from 'react'
import {
  Edit,
  EditProps,
  Datagrid,
  TextInput,
  TextField,
  DateField,
  required,
  TabbedForm,
  FormTab,
  ShowButton,
  SelectInput,
  FormDataConsumer,
  DeleteButton,
  EditButton,
  FunctionField,
  ReferenceField,
  TabbedFormTabs,
  ReferenceArrayInput,
  regex,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
} from 'react-admin'
import Chip from '@mui/material/Chip'
import { ReferenceManyFormTab } from '../../atoms/FormTab'
import { FormTabLabelBySource } from '../../atoms/FormTab'
import { ServiceSpecificationInput } from '../../templates/ServiceSpecificationInput'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { SiteModalInput } from '../../organisms/SiteModalInput'
import { StatsModalInput } from '../../organisms/StatsModalInput'
import { DocumentModalInput } from '../../organisms/DocumentModalInput'
import { ServicePortModalEdit } from '../../templates/ServicePortModalEdit'
import { StatsModalEdit } from '../../organisms/StatsModalEdit'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { DualListInput } from '@react-admin/ra-relationships'
import { FieldTooltip } from '../../atoms/FieldTooltip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { DocumentRow } from '../../organisms/DocumentRow'
import { ServicePortModalCreate } from '../../templates/ServicePortModalCreate'
import { MarkdownInput } from '@react-admin/ra-markdown'
import { ContactModalInput } from '../../organisms/ContactModalInput'
import { Grid } from '@mui/material'
import * as clientContactHooks from '../ClientContact/ClientContact.hooks'
import * as vendorContactHooks from '../VendorContact/VendorContact.hooks'
import { DateInput } from '../../atoms/DateInput'
import { TerminationReason } from '../../../types/enums/TerminationReason'
import { ServiceState } from '../../../types/enums/ServiceState'

/**
 * Page for editing a service.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 * @example
 * import { ServiceEdit } from './ServiceEdit'
 * <ServiceEdit {...props} />
 */
export const ServiceEdit: React.FC<EditProps> = (props) => {
  const clientContactRoleChoices = clientContactHooks.useRoleChoices()
  const vendorContactRoleChoices = vendorContactHooks.useRoleChoices()

  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Service" />}
      mutationMode="undoable"
    >
      <TabbedForm
        tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
      >
        <FormTab label="general">
          <ReferenceAutocomplete
            required
            label="Client"
            source="clientId"
            reference="clients/list"
            disabled
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.clientId && (
                <ReferenceAutocomplete
                  label="End Customer"
                  source="endCustomerId"
                  reference="endCustomers/list"
                  filter={{ clientId: formData.clientId }}
                  disableSyncWithLocation
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              formData.clientId && (
                <ReferenceAutocomplete
                  label="Project"
                  source="projectId"
                  reference="projects/list"
                  filter={{ clientId: formData.clientId }}
                  disableSyncWithLocation
                />
              )
            }
          </FormDataConsumer>
          <TextInput
            source="serviceNo"
            label="Service no."
            validate={[required(), regex(/^SID[0-9]{5,12}$/)]}
            fullWidth
          />
          <ReferenceAutocomplete
            label="Vendor"
            source="vendorId"
            reference="vendors/list"
            fullWidth
          />
          <TextInput
            source="vendorServiceNo"
            label="Vendor service no."
            fullWidth
          />
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="reference" fullWidth />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <ReferenceAutocomplete
                  required
                  label="Service category"
                  source="serviceCategoryId"
                  reference="serviceCategories"
                />
                <ReferenceAutocomplete
                  label={'Service type'}
                  reference="serviceTypes"
                  source="serviceTypeId"
                  filter={{ serviceCategoryId: formData?.serviceCategoryId }}
                />
                <ReferenceAutocomplete
                  label={'Access technology'}
                  reference="accessTechnologies"
                  source="accessTechnologyId"
                />
              </>
            )}
          </FormDataConsumer>
          <SelectInput
            source="state"
            validate={required()}
            choices={Object.entries(ServiceState).map(([name, id]) => ({
              id,
              name,
            }))}
            fullWidth
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.state === ServiceState.Terminated && (
                <SelectInput
                  source="terminationReason"
                  choices={Object.values(TerminationReason).map((value) => ({
                    id: value,
                    name: value,
                  }))}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <SelectInput
                  source="diversityRole"
                  defaultValue="none"
                  validate={required()}
                  choices={[
                    { id: 'none', name: 'No diversity' },
                    { id: 'primary', name: 'Primary' },
                    { id: 'secondary', name: 'Secondary' },
                    { id: 'tertiary', name: 'Tertiary' },
                  ]}
                  fullWidth
                />
                {formData.diversityRole !== 'primary' &&
                  formData.diversityRole !== 'none' && (
                    <ReferenceAutocomplete
                      label={'Diverse to primary'}
                      reference="services/list"
                      source="diverseToId"
                      filter={{
                        id: { not: formData?.id },
                        clientId: formData?.clientId,
                        diversityRole: 'primary',
                      }}
                    />
                  )}
                <ReferenceAutocomplete
                  label={'Related to'}
                  reference="services/list"
                  source="relatedToId"
                  filter={{
                    id: { not: formData?.id },
                    clientId: formData?.clientId,
                  }}
                />
              </>
            )}
          </FormDataConsumer>
          <DateInput source="deliveryWishDate" fullWidth />
          <DateInput source="provisionedAt" fullWidth />
          <DateInput source="deliveredAt" fullWidth />
          <DateInput source="cancelledAt" fullWidth />
          <DateInput source="decommissionedAt" fullWidth />
        </FormTab>
        <FormTab label="specifications">
          <ServiceSpecificationInput showAllSpecs={true} />
        </FormTab>
        <ReferenceManyFormTab
          reference="clientContracts"
          target="serviceId"
          label="client contracts"
        >
          <LabelledDatagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <TextField source="contractNo" />
            <TypeStateField source="state" />
            <ReferenceManyField
              label="Orders"
              reference="serviceOrders"
              target="contractIds"
            >
              <SingleFieldList linkType="show">
                <ChipField size="small" source="orderNo" />
              </SingleFieldList>
            </ReferenceManyField>
            <ReferenceChip
              referenceSource="clientId"
              tooltipSource="clientNo"
              source="name"
              reference="clients"
              label="Client"
              emptyText="N/A"
              link="show"
              chipSource="name"
            />
            <DateField source="periodStart" emptyText="N/A" />
            <DateField source="periodEnd" emptyText="N/A" />
            <TextPreSuffixField
              source="contractPeriod"
              label="Contract"
              suffix=" months"
              emptyText="N/A"
            />
            <DeleteButton redirect={false} />
            <EditButton />
          </LabelledDatagrid>
        </ReferenceManyFormTab>
        <ReferenceManyFormTab
          reference="vendorContracts"
          target="serviceId"
          label="vendor contracts"
        >
          <LabelledDatagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <TextField source="contractNo" />
            <TypeStateField source="state" />
            <ReferenceChip
              referenceSource="vendorId"
              tooltipSource="vendorNo"
              source="name"
              reference="vendors"
              label="Vendor"
              emptyText="N/A"
              link="show"
              chipSource="name"
            />
            <ReferenceField
              label={'Vendor offer'}
              reference="vendorOffers"
              source="vendorOfferId"
              emptyText="N/A"
            >
              <FieldTooltip titleSource="vendorNo">
                <TextField source="description" />
              </FieldTooltip>
            </ReferenceField>
            <DateField source="periodStart" emptyText="N/A" />
            <DateField source="periodEnd" emptyText="N/A" />
            <TextPreSuffixField
              source="contractPeriod"
              label="Contract"
              suffix=" months"
              emptyText="N/A"
            />
            <DeleteButton redirect={false} />
            <EditButton />
          </LabelledDatagrid>
        </ReferenceManyFormTab>
        <FormTab
          label={<FormTabLabelBySource label="Sites" source="siteIds" />}
        >
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceArrayInput
                label="Sites"
                source="siteIds"
                reference="serviceSites/list"
                filter={{ clientId: formData?.clientId }}
              >
                <DualListInput optionText="name" label={false} />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
          <SiteModalInput reference="services" resource="serviceSites" />
        </FormTab>
        <FormTab label="Contacts">
          <ArrayInput source="clientContactExtras" label="Client contacts">
            <SimpleFormIterator inline fullWidth>
              <FormDataConsumer>
                {({ getSource, formData, scopedFormData }) => (
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <ReferenceAutocomplete
                        fullWidth
                        inline
                        required
                        label="Contact"
                        source={getSource?.('id') || ''}
                        reference="clientContacts/list"
                        filter={{ clientId: formData?.clientId }}
                      />
                    </Grid>
                    <Grid item xs>
                      <SelectInput
                        required
                        fullWidth
                        source={getSource?.('role') || ''}
                        choices={clientContactRoleChoices}
                      />
                    </Grid>
                    <Grid item xs>
                      <ReferenceAutocomplete
                        fullWidth
                        inline
                        label="Site"
                        disabled={scopedFormData?.role !== 'local'}
                        source={getSource?.('serviceSiteId') || ''}
                        reference="serviceSites/list"
                        filter={{ serviceIds: formData?.id }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <BooleanInput
                        fullWidth
                        label="Receives Updates"
                        source={getSource?.('receivesUpdates') ?? ''}
                      />
                    </Grid>
                  </Grid>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <ContactModalInput reference="services" resource="clientContacts" />
          <ArrayInput source="vendorContactExtras" label="Vendor contacts">
            <SimpleFormIterator inline fullWidth>
              <FormDataConsumer>
                {({ getSource, formData, scopedFormData }) => (
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <ReferenceAutocomplete
                        fullWidth
                        inline
                        required
                        label="Contact"
                        source={getSource?.('id') || ''}
                        reference="vendorContacts/list"
                        filter={{ vendorId: formData?.vendorId }}
                      />
                    </Grid>
                    <Grid item xs>
                      <SelectInput
                        required
                        fullWidth
                        source={getSource?.('role') || ''}
                        choices={vendorContactRoleChoices}
                      />
                    </Grid>
                    <Grid item xs>
                      <ReferenceAutocomplete
                        fullWidth
                        inline
                        label="Site"
                        disabled={scopedFormData?.role !== 'local'}
                        required={scopedFormData?.role === 'local'}
                        source={getSource?.('serviceSiteId') || ''}
                        reference="serviceSites/list"
                        filter={{ serviceIds: formData?.id }}
                      />
                    </Grid>
                  </Grid>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <ContactModalInput reference="services" resource="vendorContacts" />
        </FormTab>
        <ReferenceManyFormTab
          reference="servicePorts"
          target="serviceId"
          label="ports"
        >
          <LabelledDatagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
            bulkActionButtons={false}
          >
            <TextField source="name" />
            <TypeStateField source="state" />
            <ReferenceField
              label={'Site'}
              reference="serviceSites/list"
              source="serviceSiteId"
              emptyText="N/A"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField source="type" />
            <TextField source="speed" />
            <FunctionField
              label={'Networks'}
              render={(record: any) =>
                record.networks.length === 0
                  ? 'N/A'
                  : record.networks.map((net: any) => (
                      <Chip variant="outlined" label={net.network} />
                    ))
              }
            />
            <DeleteButton redirect={false} />
            <ServicePortModalEdit />
          </LabelledDatagrid>
          <ServicePortModalCreate reference="services" />
        </ReferenceManyFormTab>
        <ReferenceManyFormTab
          reference="serviceStatistics"
          target="serviceId"
          label="statistics"
        >
          <Datagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
            bulkActionButtons={false}
          >
            <TextField source="title" />
            <TextField source="type" />
            <TextField source="module" />
            <DateField source="createdAt" showTime />
            <ShowButton />
            <StatsModalEdit />
            <DeleteButton redirect={false} />
          </Datagrid>
          <StatsModalInput reference="services" />
        </ReferenceManyFormTab>
        <ReferenceManyFormTab
          reference="documents"
          target="serviceId"
          label="documents"
        >
          <DocumentRow />
          <DocumentModalInput reference="services" />
        </ReferenceManyFormTab>
        <FormTab label={'notes'}>
          <TextInput
            source="notes"
            label="Internal notes"
            multiline
            fullWidth
          />
          <TextInput
            source="notesForClient"
            label="Notes for client"
            multiline
            fullWidth
          />
          <MarkdownInput source="deliveryNotes" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
