import { NullableSelectInput } from '../../atoms/NullableSelectInput'
import { prefixSource } from '../../particles/utils'

export const ServiceUptimeSelectInput = ({
  sourcePrefix,
}: {
  sourcePrefix?: string
}) => (
  <NullableSelectInput
    source={prefixSource('serviceUptime', sourcePrefix)}
    label="Uptime SLA (percent)"
    nullChoiceName="Best-effort"
    choices={[
      { id: 99, name: '99.0%' },
      { id: 99.5, name: '99.5%' },
      { id: 99.8, name: '99.8%' },
      { id: 99.9, name: '99.9%' },
      { id: 99.95, name: '99.95%' },
      { id: 99.99, name: '99.99%' },
    ]}
    fullWidth
  />
)
