import React, { useState } from 'react'
import { IUploadFileModal } from './UploadFileModal.types'
import {
  Button,
  SaveButton,
  FileInput,
  FileField,
  Form,
  required,
  useCreate,
  useNotify,
  useRefresh,
  useRedirect,
  useRecordContext,
} from 'react-admin'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material'
import IconUpload from '@mui/icons-material/Upload'
import IconCancel from '@mui/icons-material/Cancel'
import { FieldValues } from 'react-hook-form/dist/types/fields'

/**
 * Button that opens a dialog to upload a file.
 * @param {IUploadFileModal} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <UploadFileModal resource="documents" />
 * )
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|React Admin CreateEdit}
 * @see {@link https://mui.com/components/buttons/|MUI Buttons}
 */
export const UploadFileModal: React.FC<IUploadFileModal> = (props) => {
  const [create] = useCreate()
  const notify = useNotify()
  const record = useRecordContext()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const [showDialog, setShowDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const resource =
    props.resource === 'documents' && record
      ? `documents/${record.id}/upload`
      : 'files/upload'

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (data: FieldValues) => {
    setIsSubmitting(true)
    try {
      await create(resource, { data })
      setShowDialog(false)
      setIsSubmitting(false)
      const autoRefreshInterval = 5000
      notify('Uploading file(s)...', {
        type: 'info',
        autoHideDuration: autoRefreshInterval,
      })
      setTimeout(() => {
        redirect(`/documents/${data.id}/show/files`, 'documents')
        refresh()
        notify('File(s) have been uploaded', { type: 'success' })
      }, autoRefreshInterval)
    } catch (error: any) {
      setIsSubmitting(false)
      notify(error.message)
    }
  }

  return (
    <>
      <Button onClick={handleClick} label="Upload">
        <IconUpload />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        maxWidth="md"
        onClose={handleCloseClick}
        aria-label="Upload File(s)"
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle>Upload File</DialogTitle>
          <DialogContent>
            <FileInput
              source="file"
              label="File"
              multiple={true}
              validate={[required()]}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
            <SaveButton label="Upload" disabled={isSubmitting}>
              <IconUpload />
            </SaveButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
