import React from 'react'
import { useFormContext } from 'react-hook-form'
import {
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  minValue,
  maxValue,
  NullableBooleanInput,
} from 'react-admin'
import { Box, Typography, Link } from '@mui/material'
import { IInput } from './Input.types'
import {
  connectorChoices,
  modeChoices,
  speedChoices,
} from '../../particles/utils'

/**
 * Form input for a service specifications.
 * @property {boolean} showAllSpecs Whether to show all specs.
 * @property {Function} setShowAllSpecs A function to set whether to show all specs.
 * @returns {React.FC} The component.
 * @example
 * <ServiceSpecificationInput showAllSpecs setShowAllSpecs />
 */
export const ServiceSpecificationInput: React.FC<IInput> = (props) => {
  const form = useFormContext()
  const { category } = form.getValues()

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Box display={{ width: '100%' }}>
          {(props.showAllSpecs ||
            !category ||
            ['underlay', 'custom'].includes(category)) && (
            <>
              <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NumberInput
                    source="specifications.bandwidthDown"
                    label="Download bandwidth (Mbps)"
                    validate={[minValue(0.1), maxValue(100000)]}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NumberInput
                    source="specifications.bandwidthUp"
                    label="Upload bandwidth (Mbps)"
                    validate={[minValue(0.1), maxValue(100000)]}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NumberInput
                    source="specifications.contentionRatio"
                    label="Contention ratio (1:<value>)"
                    validate={[minValue(1), maxValue(1000)]}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NumberInput
                    source="specifications.ipPrefixV4"
                    label="Public IPv4 subnet prefix size"
                    validate={[minValue(22), maxValue(32)]}
                    step={1}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NumberInput
                    source="specifications.ipPrefixV6"
                    label="Public IPv6 subnet prefix size"
                    validate={[minValue(48), maxValue(128)]}
                    step={1}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source="specifications.routingConfig"
                    label="Routing config"
                    defaultValue="static"
                    fullWidth
                    choices={[
                      { id: 'static', name: 'Static' },
                      { id: 'vrrp', name: 'VRRP' },
                      { id: 'bgp', name: 'BGP' },
                    ]}
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source="specifications.cpeType"
                    label="CPE type"
                    fullWidth
                    defaultValue="basic"
                    choices={[
                      { id: 'basic', name: 'Basic (e.g. Modem/NTE)' },
                      {
                        id: 'advanced',
                        name: 'Advanced (e.g. Router/Gateway)',
                      },
                      { id: 'none', name: 'None (e.g Wall Jack/Patch Panel)' },
                    ]}
                  />
                </Box>
              </Box>
              <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NumberInput
                    source="specifications.bandwidthCommit"
                    label="Bandwidth commit (Mbps 95% pct)"
                    validate={[minValue(0.1), maxValue(100000)]}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NullableBooleanInput
                    source="specifications.isBurstable"
                    label="Burstable"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NullableBooleanInput
                    source="specifications.isOnSiteInstallation"
                    label="On-site installation"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NullableBooleanInput
                    source="specifications.isNovationOrder"
                    label="Novation order"
                    defaultValue={false}
                    fullWidth
                  />
                </Box>
              </Box>
            </>
          )}
          {(props.showAllSpecs || ['fabric', 'custom'].includes(category)) && (
            <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                  source="specifications.rackSpace"
                  label="Rack space"
                  validate={[minValue(1), maxValue(50)]}
                  step={1}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                  source="specifications.powerCommit"
                  label="Power commit (kW)"
                  validate={[minValue(0), maxValue(10)]}
                  step={0.5}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                  source="specifications.crossConnects"
                  label="Cross connects"
                  validate={[minValue(0), maxValue(50)]}
                  step={1}
                  fullWidth
                />
              </Box>
            </Box>
          )}
          {(props.showAllSpecs ||
            !category ||
            ['private', 'custom'].includes(category)) && (
            <>
              <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source="specifications.carrier"
                    label="Carrier name"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source="specifications.cableSystemsUsed"
                    label="Cable systems used"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source="specifications.lastMileProvider"
                    label="Last mile provider (LMP)"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NumberInput
                    source="specifications.expectedRtd"
                    label="Expected round trip delay (ms)"
                    validate={[minValue(0), maxValue(10000)]}
                    step={1}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NumberInput
                    source="specifications.guaranteedRtd"
                    label="Guaranteed round trip delay (ms)"
                    validate={[minValue(0), maxValue(10000)]}
                    step={1}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <NullableBooleanInput
                    source="specifications.isProtected"
                    label="Is protected"
                    fullWidth
                  />
                </Box>
              </Box>
            </>
          )}
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source="specifications.portType"
                label="Port type"
                fullWidth
                choices={[
                  { id: 'fiber', name: 'Fiber' },
                  { id: 'copper', name: 'Copper' },
                ]}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source="specifications.portConnector"
                label="Port connector"
                choices={connectorChoices(formData, 'specifications.portType')}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source="specifications.portMode"
                label="Port mode"
                choices={modeChoices(formData, 'specifications.portType')}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source="specifications.portSpeed"
                label="Port speed"
                choices={speedChoices(formData, 'specifications.portType')}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <NumberInput
                source="specifications.portCount"
                label="Port count"
                validate={[minValue(1), maxValue(10)]}
                defaultValue="1"
                step={1}
                fullWidth
              />
            </Box>
          </Box>
          {!props.showAllSpecs &&
            category &&
            category !== 'custom' &&
            typeof props.setShowAllSpecs === 'function' && (
              <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="subtitle2" align="center">
                    Only displaying relevant specs for service category&nbsp;
                    {category}.&nbsp;
                    <Link
                      onClick={() => {
                        if (typeof props.setShowAllSpecs === 'function') {
                          props.setShowAllSpecs(!props.showAllSpecs)
                        }
                      }}
                    >
                      Show all fields
                    </Link>
                  </Typography>
                </Box>
              </Box>
            )}
        </Box>
      )}
    </FormDataConsumer>
  )
}
