import React, { useState } from 'react'
import {
  FormDataConsumer,
  useCreate,
  useNotify,
  useRefresh,
  Confirm,
  useRedirect,
  SaveButton,
} from 'react-admin'
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material'
import AutoFixHighOutlined from '@mui/icons-material/AutoFixHighOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { FileGeneratorPreview } from './Preview.component'
import { FileGeneratorContext } from './Context.component'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { ExtendedWizardToolbar } from '../../atoms/ExtendedWizardToolbar'

/**
 * Generates a file based on the selected template.
 * @returns {React.FC}
 */
export const FileGenerator: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [, setIsSubmitting] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const [create] = useCreate()

  const handleClick = () => {
    setShowDialog(true)
  }

  const closeClick = (reason?: string) => {
    // prevent backdrop click close
    if (reason && reason === 'backdropClick') {
      return false
    }
    setConfirmClose(true)
  }

  const dialogCloseClick = (event?: object, reason?: string) => {
    closeClick(reason)
  }

  const buttonCloseClick = (event?: React.MouseEvent, reason?: string) => {
    closeClick(reason)
  }

  const handleClose = () => {
    setConfirmClose(false)
    setShowDialog(false)
  }

  const handleSubmit = async (data: FieldValues) => {
    setIsSubmitting(true)

    const { id, ...payload } = data

    if (payload?.context?.model === 'Client') {
      payload.context.id = payload.clientId
    }

    try {
      await create(
        id ? `documents/${id}/generate` : `documents/generate`,
        { data: payload },
        { returnPromise: true }
      )
      setIsSubmitting(false)
      setShowDialog(false)
      const autoRefreshInterval = 5000
      notify('Generating file...', {
        type: 'info',
        autoHideDuration: autoRefreshInterval,
      })
      setTimeout(() => {
        redirect(`/documents/${id}/show/files`, 'documents')
        refresh()
        notify('File generated successfully', { type: 'success' })
      }, autoRefreshInterval)
    } catch (error: any) {
      notify(error.message || error, { type: 'warning' })
    }
  }

  return (
    <>
      <Button aria-label="generate" onClick={handleClick}>
        <AutoFixHighOutlined />
        &nbsp;{'Generate'}
      </Button>
      <Confirm
        isOpen={confirmClose}
        title="Cancel File Generation"
        content="Are you sure you want to cancel file generation?"
        onConfirm={handleClose}
        onClose={() => {
          setConfirmClose(false)
        }}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={dialogCloseClick}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          File Generator
          <Button
            sx={{ margin: 0, float: 'right' }}
            size={'small'}
            onClick={buttonCloseClick}
          >
            <CloseIcon />
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <WizardForm
            onSubmit={handleSubmit}
            toolbar={
              <ExtendedWizardToolbar saveButton={<SaveButton alwaysEnable />} />
            }
          >
            <WizardFormStep label="Template">
              <FormDataConsumer>
                {({ formData }) => (
                  <ReferenceAutocomplete
                    required
                    source="templateId"
                    reference="templates/list"
                    filter={{ categoryId: formData?.categoryId }}
                    sort={{ field: 'key', order: 'ASC' }}
                  />
                )}
              </FormDataConsumer>
            </WizardFormStep>
            <WizardFormStep label="Context">
              <FileGeneratorContext />
            </WizardFormStep>
            <WizardFormStep label="Preview">
              <FileGeneratorPreview />
            </WizardFormStep>
          </WizardForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
