import { NullableSelectInput } from '../../atoms/NullableSelectInput'
import { prefixSource } from '../../particles/utils'

export const MeanTimeToRepairSelectInput = ({
  sourcePrefix,
}: {
  sourcePrefix?: string
}) => (
  <NullableSelectInput
    source={prefixSource('meanTimeToRepair', sourcePrefix)}
    label="MTTR"
    nullChoiceName="Best-effort"
    choices={[
      { id: 4, name: '4 hours' },
      { id: 8, name: '8 hours' },
      { id: 12, name: '12 hours' },
      { id: 24, name: '24 hours (NBD)' },
    ]}
    fullWidth
  />
)
