import { useState, useCallback } from 'react'
import { useNotify, useRefresh, useDataProvider } from 'react-admin'
import { ListItem, TransferServicePayload } from './TransferServiceModal.types'

export const useTransferService = (serviceId: string | number) => {
  const [selectedClient, setSelectedClient] = useState<ListItem | null>(null)
  const [selectedProject, setSelectedProject] = useState<ListItem | null>(null)
  const [selectedEndCustomer, setSelectedEndCustomer] =
    useState<ListItem | null>(null)
  const [includeRelatedServices, setIncludeRelatedServices] = useState(true)
  const [previewServices, setPreviewServices] = useState<
    { id: string; serviceNo: string; name: string }[]
  >([])

  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const resetSelections = useCallback(() => {
    setSelectedClient(null)
    setSelectedProject(null)
    setSelectedEndCustomer(null)
    setPreviewServices([])
  }, [])

  const fetchPreview = useCallback(async () => {
    if (!selectedClient || !serviceId) return

    try {
      const response = await dataProvider.customRequest(
        'services',
        `${serviceId}/transfer/preview`,
        {
          data: {
            clientId: selectedClient?.id ?? null,
            projectId: selectedProject?.id ?? null,
            endCustomerId: selectedEndCustomer?.id ?? null,
            includeRelatedServices,
          },
          method: 'POST',
        }
      )

      if (response?.json) {
        setPreviewServices(response.json)
      }
    } catch (error) {
      notify('Error fetching transfer preview', { type: 'error' })
    }
  }, [
    dataProvider,
    serviceId,
    selectedClient,
    selectedProject,
    selectedEndCustomer,
    includeRelatedServices,
    notify,
  ])

  const handleTransfer = useCallback(async () => {
    if (!selectedClient) {
      notify('Please select a client', { type: 'warning' })
      return false
    }

    if (!serviceId) {
      notify('Service not found', { type: 'warning' })
      return false
    }

    const payload: TransferServicePayload = {
      clientId: selectedClient?.id ?? null,
      projectId: selectedProject?.id ?? null,
      endCustomerId: selectedEndCustomer?.id ?? null,
      includeRelatedServices,
    }

    try {
      const response = await dataProvider.customRequest(
        'services',
        `${serviceId}/transfer`,
        {
          data: payload,
          method: 'POST',
        }
      )

      if (
        response?.status !== 200 ||
        response?.json?.data?.clientId !== selectedClient.id
      ) {
        throw new Error(response?.json?.error || 'Unexpected response')
      }

      notify('Service(s) transferred successfully', { type: 'success' })
      refresh()
      return true
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Error moving service'
      notify(errorMessage, { type: 'error' })
      return false
    }
  }, [
    selectedClient,
    selectedProject,
    selectedEndCustomer,
    serviceId,
    dataProvider,
    notify,
    refresh,
    includeRelatedServices,
  ])

  return {
    selectedClient,
    setSelectedClient,
    selectedProject,
    setSelectedProject,
    selectedEndCustomer,
    setSelectedEndCustomer,
    includeRelatedServices,
    setIncludeRelatedServices,
    previewServices,
    fetchPreview,
    handleTransfer,
    resetSelections,
  }
}
