import { SelectInput, SelectInputProps } from 'react-admin'

export function NullableSelectInput({
  nullChoiceName,
  ...props
}: SelectInputProps & { nullChoiceName: string }) {
  return (
    <SelectInput
      {...props}
      emptyText={nullChoiceName}
      emptyValue={nullChoiceName}
      parse={(value) => (value === nullChoiceName ? null : value)}
      format={(value) => (value === null ? nullChoiceName : value)}
    />
  )
}
