import React from 'react'
import {
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  TextField,
  BooleanInput,
  Link,
} from 'react-admin'
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout'
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Alert,
  Stack,
  ListItemText,
  Button,
  Tooltip,
} from '@mui/material'
import { FormRecord } from './TransferServiceForm.types'
import { PermissiveWizardToolbar } from '../PermissiveWizardToolbar'

interface TransferServiceFormProps {
  formRecord: FormRecord
  onSubmit: () => void
  onClientChange: (value: string) => void
  onProjectChange: (value: string) => void
  onEndCustomerChange: (value: string) => void
  onIncludeRelatedServicesChange?: (checked: boolean) => void
  selectedClientId?: string | number | null
  selectedProjectId?: string | number | null
  selectedEndCustomerId?: string | number | null
  previewServices?: { id: string; serviceNo: string; name: string }[]
}

export const TransferServiceForm: React.FC<TransferServiceFormProps> = ({
  formRecord,
  onSubmit,
  onClientChange,
  onProjectChange,
  onEndCustomerChange,
  onIncludeRelatedServicesChange,
  selectedClientId,
  selectedProjectId,
  selectedEndCustomerId,
  previewServices = [],
}) => {
  return (
    <WizardForm
      record={formRecord}
      onSubmit={onSubmit}
      toolbar={formRecord.clientId ? <PermissiveWizardToolbar /> : undefined}
      validate={(values) => {
        const errors: Record<string, string> = {}
        if (!values.clientId) {
          errors.clientId = 'Please select a client'
        }
        return errors
      }}
    >
      <WizardFormStep label="Select Target">
        <ReferenceInput source="clientId" reference="clients/list">
          <AutocompleteInput
            label="Client"
            onChange={onClientChange}
            optionText="name"
            fullWidth
          />
        </ReferenceInput>
        {selectedClientId && (
          <>
            <Typography variant="body2">
              Choose an existing project from the list below or{' '}
              <Link
                to={{
                  pathname: `/projects/create`,
                  search: `?source=${JSON.stringify({
                    clientId: selectedClientId,
                  })}`,
                }}
              >
                add a new one
              </Link>
              .
            </Typography>
            <ReferenceInput
              source="projectId"
              reference="projects/list"
              filter={{ clientId: selectedClientId }}
            >
              <AutocompleteInput
                label="Project"
                onChange={onProjectChange}
                optionText="name"
                fullWidth
                emptyText="No project"
              />
            </ReferenceInput>
            <Typography variant="body2">
              Choose an existing end customer from the list below or{' '}
              <Link
                to={{
                  pathname: `/endCustomers/create`,
                  search: `?source=${JSON.stringify({
                    clientId: selectedClientId,
                  })}`,
                }}
              >
                add a new one
              </Link>
              .
            </Typography>
            <ReferenceInput
              source="endCustomerId"
              reference="endCustomers/list"
              filter={{ clientId: selectedClientId }}
            >
              <AutocompleteInput
                label="End Customer"
                onChange={onEndCustomerChange}
                optionText="name"
                fullWidth
                emptyText="No end customer"
              />
            </ReferenceInput>
            <BooleanInput
              source="includeRelatedServices"
              label="Also transfer all related services recursively, if any"
              onChange={(event) => {
                onIncludeRelatedServicesChange?.(event.target.checked)
              }}
            />
          </>
        )}
      </WizardFormStep>
      <WizardFormStep label="Confirmation">
        <Stack spacing={2} marginBottom={2}>
          <Box>
            <Typography variant="h6" gutterBottom>
              Being transferred:
            </Typography>
            <Stack direction="row" gap={2} flexWrap="wrap">
              {previewServices.map((record, index) => (
                <Tooltip key={index} title="Click to view in a new tab">
                  <Box>
                    <Button
                      variant="outlined"
                      component={Link}
                      target={'_blank'}
                      to={{
                        pathname: `/services/${record.id}/show`,
                      }}
                    >
                      <ListItemText
                        primary={record.serviceNo}
                        secondary={record.name}
                      />
                    </Button>
                  </Box>
                </Tooltip>
              ))}
            </Stack>
          </Box>
          <Box>
            <Typography variant="h6" gutterBottom>
              Transferring to:
            </Typography>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Client</strong>
                  </TableCell>
                  <TableCell>
                    {selectedClientId ? (
                      <ReferenceField
                        reference="clients/list"
                        source="id"
                        record={{ id: selectedClientId }}
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    ) : (
                      'No client'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Project</strong>
                  </TableCell>
                  <TableCell>
                    {selectedProjectId ? (
                      <ReferenceField
                        reference="projects/list"
                        source="id"
                        record={{ id: selectedProjectId }}
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    ) : (
                      'No project'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>End Customer</strong>
                  </TableCell>
                  <TableCell>
                    {selectedEndCustomerId ? (
                      <ReferenceField
                        reference="endCustomers/list"
                        source="id"
                        record={{ id: selectedEndCustomerId }}
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    ) : (
                      'No end customer'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box>
            <Alert severity="warning">
              Please confirm that all information is correct before proceeding.
              This action will update the services relationships and cannot be
              undone.
            </Alert>
          </Box>
        </Stack>
      </WizardFormStep>
    </WizardForm>
  )
}
